import React from "react";
import { DocumentData } from "firebase/firestore";
import { Box, Flex, Heading } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { useParams } from "react-router-dom";
import { getVisitor } from "../api/api";

export const VisitorPage = () => {
  const { id } = useParams();
  const [visitor, setVisitor] = React.useState<DocumentData>();

  React.useEffect(() => {
    async function asyncFunc() {
      if (id) {
        const visitor = await getVisitor(id);
        setVisitor(visitor);
      }
    }
    asyncFunc();
  }, []);

  return (
    <>
      <Flex
        className="titlebar"
        minWidth="max-content"
        alignItems="center"
        gap="2"
      >
        <Box p="2">
          <Heading size="md">
            Who's visiting - visit information for {id}
          </Heading>
        </Box>
      </Flex>
      <Box flex="1" textAlign="left"></Box>

      {visitor && <ReactJson style={{ margin: "32px" }} src={visitor} />}
    </>
  );
};
