import { DocumentData } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { getVisitors, logVisitorInfo } from "../api/api";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { Visitors } from "./components/visitors";
import { randomString } from "../utils";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export const Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageId, setPageId] = React.useState<string>(id ? id : "root");
  const [visitors, setVisitors] = React.useState<DocumentData>();
  const [currentVisitorId] = React.useState<string>(randomString(16));
  const toast = useToast();

  React.useEffect(() => {
    logAndRetrieveVisitors();
  }, [id]);

  const logAndRetrieveVisitors = React.useCallback(async () => {
    await logVisitorInfo(pageId, currentVisitorId);
    const visitors = await getVisitors(pageId);
    setVisitors(visitors);
  }, [pageId, currentVisitorId]);

  const onClickNewPage = () => {
    const pageId = randomString(6);
    setPageId(pageId);
    navigate(`/p/${pageId}`);
  };

  const onShareLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);

    toast({
      title: "Copied share link to clipboard.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <>
      <Flex
        className="titlebar"
        minWidth="max-content"
        alignItems="center"
        gap="2"
      >
        <Box p="2">
          <Heading size="md">Who visited - {pageId}</Heading>
        </Box>
        <Spacer />
        <ButtonGroup gap="2">
          <Button colorScheme="teal" onClick={onClickNewPage}>
            New Visitor Page
          </Button>
          <Button colorScheme="teal" onClick={onShareLink}>
            Share link
          </Button>
        </ButtonGroup>
      </Flex>
      {visitors && (
        <Visitors visitors={visitors} currentVisitorId={currentVisitorId} />
      )}
    </>
  );
};

export default Page;
