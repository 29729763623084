import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { store } from "../firebase";
import UAParser from "ua-parser-js";

export const logVisitorInfo = async (
  pageId: string,
  currentVisitorId: string
) => {
  var parser = new UAParser();
  parser.getResult();
  let geolocationData = undefined;
  try {
    geolocationData = await fetch(`https://geolocation-db.com/json/`).then(
      (res) => res.json()
    );
  } catch (error) {
    console.log("Geolocation data blocked");
  }

  try {
    const docRef = await addDoc(collection(store, "visitor"), {
      page: pageId,
      visitor_id: currentVisitorId,
      browser_props: {
        ...parser.getResult(),
        screen_width: window.screen.width,
        screen_height: window.screen.height,
      },
      timestamp: new Date().getTime(),
      ip_address: geolocationData?.IPv4,
      location: geolocationData,
    });

    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getVisitors = async (pageId: string) => {
  const q = query(
    collection(store, "visitor"),
    where("page", "==", pageId),
    orderBy("timestamp", "desc")
  );
  const querySnapshot = await getDocs(q);
  let data: Array<DocumentData> = [];
  querySnapshot.forEach((doc) => {
    data.push(convertToVisitorData(doc));
  });
  return data;
};

export const getVisitor = async (visitorId: string) => {
  const docRef = doc(store, "visitor", visitorId);
  const docSnap = await getDoc(docRef);
  const visitor = convertToVisitorData(docSnap);
  return visitor;
};

export const convertToVisitorData = (doc: DocumentSnapshot<DocumentData>) => {
  const record = doc.data();
  const id = doc.id;
  const timeString = new Date(record?.timestamp).toLocaleString("en-US");
  const visitor = {
    id: id,
    time: timeString,
    ...record,
  };
  return visitor;
};
