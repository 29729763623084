import React from "react";
import { DocumentData } from "firebase/firestore";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Badge,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { LinkIcon } from "@chakra-ui/icons";

type VisitorsComponentProps = {
  visitors: DocumentData;
  currentVisitorId: string;
};

export const Visitors = (props: VisitorsComponentProps) => {
  const { visitors, currentVisitorId } = props;
  const toast = useToast();
  const onVisitorCopy = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    visitorId: string
  ) => {
    const url = window.location.origin + `/v/${visitorId}`;
    navigator.clipboard.writeText(url);
    toast({
      title: "Copied visitor link to clipboard",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    event.stopPropagation();
  };

  return (
    <Accordion className={"visitor-accordion"} defaultIndex={[0]} allowMultiple>
      {visitors.length > 0 &&
        visitors.map((visitor: any) => (
          <AccordionItem key={visitor.id}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {visitor.browser_props.browser.name} on{" "}
                  {visitor.browser_props.os.name} at {visitor.time}
                  {visitor.visitor_id === currentVisitorId && (
                    <>
                      <Badge style={{ margin: "0 16px" }} colorScheme="green">
                        Current
                      </Badge>
                    </>
                  )}
                </Box>
                <IconButton
                  onClick={(event) => onVisitorCopy(event, visitor.id)}
                  aria-label="Link to visitor"
                  icon={<LinkIcon />}
                />
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ReactJson displayDataTypes={false} src={visitor} />
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
};
