import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import Page from "./page/page";
import { ChakraProvider } from "@chakra-ui/react";
import { VisitorPage } from "./visitor-page/visitor-page";

export default function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ChakraProvider>
  );
}

function Routes() {
  return useRoutes([
    {
      path: "/p/:id",
      element: <Page />,
    },
    {
      path: "/v/:id",
      element: <VisitorPage />,
    },
    { path: "*", element: <Navigate to="/p/root" replace /> },
  ]);
}
